import React from 'react'
import { Moment } from 'moment'
import { CalendarHC } from 'components'

interface IVisitPeriod {
    defaultValue: Moment | string
    disabled: boolean
    dateInicio: Moment
    dateFin: Moment
    onChange: (value?: any) => void
}

export const VisitPeriod = (props: IVisitPeriod) => {
  return <div className='edit'>
  <div>
    <h3 style={{ marginBottom: '11px' }}>Fecha/Hora de la Visita:</h3>
    <CalendarHC
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        onChange={props.onChange}
        dateInicio={props.dateInicio}
        dateFin={props.dateFin}
        />
  </div>
</div>
}
