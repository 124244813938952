import React, { useContext, useState } from 'react'
import { Empty, Skeleton } from 'antd'
import { AuthLoginContext } from '../../../services/authContextService'
import { PanelPrestaciones } from './PanelPrestaciones'
import { IEdited } from '../interfaces'
import { useGetClinicHistory } from '../hooks/use-get-clinic-history'

interface IDetailsHcProps {
  month: string
  year: number
  Editing: IEdited
  getOwnHC: number
}

export const DetailsHC = (props: IDetailsHcProps) => {
  const { month, year, getOwnHC, Editing } = props
  // const { edited, setEdited } = Editing

  const [cancel, setCancel] = useState<boolean>(true)
  const numberPatient = parseInt(window.location.toString().split('/')[4])
  const userContext = useContext(AuthLoginContext)

  const { loading, data, setData, refreshData } = useGetClinicHistory({
    params: {
      profesionalCode: userContext.userInfo.codigoProfesional,
      patientCode: numberPatient,
      user: userContext.userInfo.nombreUsuario,
      role: 0,
      numAllHc: getOwnHC
    },
    year,
    month
  })

  // interface IButtonEdit {
  //   edited: boolean
  //   year: number
  //   month: string
  //   clicked: () => void
  // }
  // const ButtonEdit = (props: IButtonEdit) => {
  //   const currentDate = moment()
  //   const currentYear = currentDate.year()
  //   const currentPeriod = (currentDate.month() + 1)

  //   /** Valido hasta que dia del mes puedo modificar HC viejas */
  //   const daysEnabled = (moment().date() <= 15)
  //   /** Valido el periodo de la hc y tambien si estoy dentro del rando de dias para modificarla */
  //   const enabledOldHC = daysEnabled && parseInt(props.month) === ((currentPeriod - 1) === 0 ? 12 : (currentPeriod - 1))
  //   /** Valido si las hc si son del periodo actual */
  //   const currentPeriodEnabled = parseInt(props.month) === (currentPeriod) && props.year === (currentYear)
  //   return currentPeriodEnabled || enabledOldHC ? <Button disabled={props.edited} onClick={props.clicked} type='primary'>Editar</Button> : <></>
  // }

  // const FillCalendarX = (props: any) => {
  //   if (props.fechaHoraVisita && props.comment) return <BsCalendarCheckFill style={{ fontSize: '30px', color: '#36bc9b' }} />
  //   if (props.fechaHoraVisita && !props.comment) return <BsCalendarCheckFill style={{ fontSize: '30px', color: '#2196f3' }} />

  //   return <BsFillCalendar2XFill style={{ fontSize: '30px', color: '#f00c0c' }} />
  // }

  // const onEdit = () => {
  //   setEdited(!edited)
  // }

  // const onCancel = () => {
  //   setEdited(true)
  //   setCancel(!cancel)
  // }
  // const onSave = async (dataPutHC: Detalle) => {
  //   const dataFormater: ClinicHistoryPut = { ...dataPutHC }

  //   if (!dataPutHC.fechaVisita) {
  //     return OpenNotification('Tiene Datos Incompletos', 'Se deben completar todos los datos Fecha de la visita...', 'top', 'error')
  //   }
  //   if (dataPutHC.entradaFechaCarga && dataPutHC.salidaFechaCarga && (!dataPutHC.detalle || !dataPutHC.tiempoAtencion)) {
  //     return OpenNotification('Tiene Datos Incompletos', 'Se deben completar todos los datos (Tiempo, Comentario)...', 'top', 'error')
  //   }
  //   if (dataPutHC.entradaFechaCarga && dataPutHC.salidaFechaCarga && (!dataPutHC.detalle || dataPutHC.detalle.length < 6)) {
  //     return OpenNotification('Comentario Corto', 'Debe ingresar un comentario con un minimo de 6 caracteres.', 'top', 'error')
  //   }
  //   const response = await clinicHistoryRepoPut(dataFormater)
  //   if (response?.status === 204) onCancel()
  // }

  // const onReset = async (dataPutHC: ClinicHistoryPut) => {
  //   const response = await clinicHistoryRepoPut({
  //     ...dataPutHC,
  //     firma: null,
  //     entradaLatitud: null,
  //     entradaLongitud: null,
  //     entradaFechaCarga: null,
  //     salidaFechaCarga: null,
  //     salidaLatitud: null,
  //     salidaLongitud: null,
  //     detalle: null,
  //     fechaVisita: null,
  //     tiempoAtencion: null
  //   })
  //   if (response?.status === 204) onCancel()
  // }

  return (<>
  <Skeleton loading={loading}>
      <PanelPrestaciones
        data={data}
        month={Number(month)}
        year={year}
        refresh={refreshData}
        setData={setData}
        canceled={{ cancel, setCancel }}
        edited={Editing} />
    { !data || data.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <></>}
  </Skeleton>
</>)
}
