/*eslint-disable */
import { useEffect, useState } from 'react'
import SelectorYearMonth from '../../components/Inputs/SelectorYearMonth'
import {  Skeleton, Tabs } from 'antd'
import { DetailsHC } from './components/details'
import { usePatient } from '../../services/patients/patientContextServices'
import { useHeaderPage } from '../../services/context/common/headerPageContextServices'
import { FullNamePatient } from '../../components'

const HC = () => {
  const usePatients = usePatient()
  const useHeader = useHeaderPage()
    
  const currentDate = new Date()
  const [edited, setEdited] = useState(true)
  const [selectValueYear, setselectvalueYear] = useState<number>(Number(currentDate.getFullYear()))
  const [selectValueMonth, setselectvalueMonth] = useState<string>(String(currentDate.getMonth() + 1))

  useEffect(() => {
    useHeader.setHeaderPageTitle('Historia Clinica')
    useHeader.setOnBack(`/Paciente/${usePatients.patientCode}`)
    useHeader.setActiveNavBot(true)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])
  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <div className='contentHistory'>
      <FullNamePatient />     
      <SelectorYearMonth disabledSelect={false} 
        defaulYear={selectValueYear} 
        defaulMonth={selectValueMonth} 
        selectYear={setselectvalueYear} 
        selectMonth={setselectvalueMonth}
      />

      <Tabs
      defaultActiveKey="1"
      className='tabHistory'
      // onChange={onchange}
      items={[
        {
          label: 'Mis H.C.',
          key: '1',
          disabled: !edited,
          children: 
            <DetailsHC month={selectValueMonth} year={selectValueYear} Editing={{edited, setEdited}} getOwnHC={0}/>
        },
        {
          label: 'H.C. de Otros',
          key: '2',
          // disabled: !edited,          
          children:
          <DetailsHC month={selectValueMonth} year={selectValueYear} Editing={{edited, setEdited}} getOwnHC={1}/>
        
        }
      ]}
      ></Tabs>
  </div>
  )
}

export default HC
