import React, { useState } from 'react'
import { Alert, Collapse, Space } from 'antd'
import moment from 'moment'
import { setIn } from 'timm'

import { Geolocation } from './location'
import { InputTime, SaveAndCancelButton } from 'components'
import { VisitPeriod } from './visitPeriod'
import { Signature } from './signature'
import { clinicHistoryRepoPut } from 'repositories'
import { useGeolocation } from '../../../hooks'
import useHcEditValidation from '../hooks/use-hc-edit-validation'
import { isHCValid } from './hcValidations'

import { IDetalle, IDetailsGroup, IEdited } from '../interfaces'
import { ICanceled } from './PanelPrestaciones'
import { ClinicHistoryPut } from 'interfaces'
import TextArea from 'antd/lib/input/TextArea'
import { FillCalendarX } from '../utils/calendarIcons'
const { Panel } = Collapse

interface IPanelDetailsProps {
  value: IDetalle[]
  edited: IEdited
  canceled: ICanceled
  year: number
  month: number
  setData: (value: IDetailsGroup[]) => void
  data?: IDetailsGroup[]
  dataGroup: IDetailsGroup
  refresh: () => void
}

export const PanelDetails: React.FC<IPanelDetailsProps> = ({
  value,
  year,
  month,
  setData,
  data,
  refresh,
  dataGroup,
  edited: { edited, setEdited },
  canceled: { cancel, setCancel }
}) => {
  const [resGeo, setResGeo] = useState({ status: '', message: '', extra: '' })
  const geoStatus = useGeolocation()
  const { validateEditAndShowButton } = useHcEditValidation()
  const onEdit = () => {
    setResGeo(geoStatus)
    setEdited(!edited)
  }

  const onCancel = () => {
    setEdited(true)
    setCancel(!cancel)
  }

  const onSave = async (dataPutHC: IDetalle) => {
    if (!isHCValid(dataPutHC)) return
    const response = await clinicHistoryRepoPut(dataPutHC)
    refresh()
    if (response?.status === 204) onCancel()
  }

  const onReset = async (dataPutHC: ClinicHistoryPut) => {
    const response = await clinicHistoryRepoPut({
      ...dataPutHC,
      firma: null,
      entradaLatitud: null,
      entradaLongitud: null,
      entradaFechaCarga: null,
      salidaFechaCarga: null,
      salidaLatitud: null,
      salidaLongitud: null,
      detalle: null,
      fechaVisita: null,
      tiempoAtencion: null
    })
    if (response?.status === 204) onCancel()
    refresh()
  }

  const handleGeolocationChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const geoSelect = e.target.value
    const isEntrada = geoSelect === 'entrada'
    const dateField = isEntrada ? 'entradaFechaCarga' : 'salidaFechaCarga'
    const latField = isEntrada ? 'entradaLatitud' : 'salidaLatitud'
    const lonField = isEntrada ? 'entradaLongitud' : 'salidaLongitud'

    const newDateHC = setIn(isEntrada ? value : data, [index, dateField], new Date()) as IDetalle[]

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLat = setIn(newDateHC, [index, latField], String(position.coords.latitude)) as IDetalle[]
          const newLon = setIn(newLat, [index, lonField], String(position.coords.longitude)) as IDetalle[]
          const newDataGroup = setIn(dataGroup, ['value'], newLon) as IDetailsGroup
          setData([newDataGroup])
        },
        (error) => {
          console.error('Error getting geolocation:', error)
          alert('Error getting geolocation')
        }
      )
    } else {
      alert('Geolocation is not supported by this browser')
    }
  }

  const handleVisitPeriodChange = (date: moment.Moment | null, index: number) => {
    if (!date) return
    const newDateHC = setIn(value, [index, 'fechaVisita'], date.toDate()) as IDetalle[]
    const newDataGroup = setIn(dataGroup, ['value'], newDateHC) as IDetailsGroup
    setData([newDataGroup])
  }

  const handleTimeChange = (time: moment.Moment | null, index: number) => {
    if (!time) return
    const TimeDate = `${time.format('HH')}:${time.format('mm')}:00`
    const newTimeHC = setIn(value, [index, 'tiempoAtencion'], TimeDate) as IDetalle[]
    const newDataGroup = setIn(dataGroup, ['value'], newTimeHC) as IDetailsGroup
    setData([newDataGroup])
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const newComment = setIn(value, [index, 'detalle'], e.target.value) as IDetalle[]
    const newDataGroup = setIn(dataGroup, ['value'], newComment) as IDetailsGroup
    setData([newDataGroup])
  }

  const renderAlert = () => {
    if (resGeo.status === 'GEO_ACTIVE' || resGeo.status === '') return null
    return (
      <Alert
        style={{ marginTop: 10 }}
        message={resGeo.status}
        description={resGeo.message}
        type="error"
        closable
        onClose={() => setResGeo({ status: '', message: '', extra: '' })}
      />
    )
  }

  const renderPanelContent = (detalle: IDetalle, index: number) => (
    <div className="clinicHistory--container">
      {renderAlert()}
      {validateEditAndShowButton({
        year,
        month,
        edited: !edited,
        clicked: onEdit,
        indexActual: index,
        hcDetalles: value
      })}
      <Geolocation
        edited={false}
        defaultValueEntrada={!detalle.entradaFechaCarga ? '' : 'entrada'}
        defaultValueSalida={!detalle.salidaFechaCarga ? '' : 'salida'}
        onChange={(e) => handleGeolocationChange(e, index)}
        value={detalle}
      />
      <VisitPeriod
        disabled={edited}
        defaultValue={detalle.fechaHoraVisita ? moment(detalle.fechaHoraVisita, 'YYYY-MM-DD HH:mm') : ''}
        dateInicio={moment(value[index - 1]?.fechaHoraVisita || detalle.fechaInicio)}
        dateFin={moment(detalle.fechaFin)}
        onChange={(date) => handleVisitPeriodChange(date, index)}
      />
      <div className='TimeSpam'>
        <h3 className='title-Hc'>Tiempo de atención:</h3>
        <Space direction="vertical" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <InputTime
            disabled={edited}
            format={'HH:mm'}
            defaultValue={moment(detalle.tiempoAtencion, 'HH:mm')}
            bloquearDuracion={detalle.bloquearDuracion}
            onChange={(time) => handleTimeChange(time, index)}
          /> (Hora : Minuto)
        </Space>
      </div>
      <div className='CommentsHC'>
        <h3 className='title-Hc'>Comentario:</h3>
        <TextArea
          defaultValue={detalle.detalle}
          disabled={edited}
          id='comentario'
          showCount
          minLength={15}
          rows={5}
          placeholder="Comments.."
          onChange={(e) => handleCommentChange(e, index)}
        />
      </div>
      <Signature disabled={edited} index={index} data={dataGroup} setData={setData} />
      <SaveAndCancelButton
        disabled={edited}
        clickedCancel={onCancel}
        clickedSave={() => onSave(value[index])}
        clickExtraButton={() => onReset(value[index])}
        extraButtonHc={!!value[index].detalle && (!value[index + 1] || !value[index + 1].detalle)}
        extraClass={'Button-guardar-cancelar'}
      />
    </div>
  )

  const renderPanels = () => {
    return value?.map((detalle, index) => {
      const label = detalle.fechaHoraVisita && detalle.detalle
        ? detalle.fechaHoraVisita
        : detalle.fechaHoraVisita && !detalle.detalle
          ? `${detalle.fechaHoraVisita} | Planificada`
          : 'Visita no realizada'
      return (
        <Panel
          header={`${index + 1} - ${label}`}
          key={index + 1}
          extra={<FillCalendarX fechaHoraVisita={detalle.fechaHoraVisita} comment={detalle.detalle} />}
          collapsible={edited ? 'header' : 'disabled'}
        >
          {renderPanelContent(detalle, index)}
        </Panel>
      )
    })
  }

  return (
    <Collapse accordion expandIconPosition="start">
      {renderPanels()}
    </Collapse>
  )
}
