import React from 'react'
import moment from 'moment'
import { IDetalle } from '../interfaces'
import { Button } from 'antd'

export interface IButtonEdit {
    hcDetalles: IDetalle[]
    edited: boolean
    indexActual: number
    year: number
    month: number
    clicked: () => void
}

const useHcEditValidation = () => {
  const validateEditAndShowButton = (props: IButtonEdit) => {
    const currentDate = moment()
    const currentYear = currentDate.year()
    const currentPeriod = currentDate.month() + 1

    // Valido si la hc anterior esta completa.
    const hcAnteriorCompleta =
            props.indexActual === 0 || (props.indexActual > 0 && props.hcDetalles[props.indexActual - 1]?.detalle !== null)

    /** Valido hasta que dia del mes puedo modificar HC viejas */
    const daysEnabled = moment().date() <= 15

    /** Valido el periodo de la hc y tambien si estoy dentro del rango de días para modificarla */
    const enabledOldHC = daysEnabled && props.month === ((currentPeriod - 1) === 0 ? 12 : (currentPeriod - 1))

    /** Valido si las hc si son del periodo actual */
    const currentPeriodEnabled = (props.month === currentPeriod) && (props.year === currentYear)

    // Retorna el botón si se cumplen las condiciones
    return hcAnteriorCompleta && (currentPeriodEnabled || enabledOldHC)
      ? (
            <Button disabled={props.edited} onClick={props.clicked} type='primary'>
              Editar
            </Button>
        )
      : null
  }

  return { validateEditAndShowButton }
}
export default useHcEditValidation
