import React from 'react'
import { Collapse } from 'antd'
import { PanelDetails } from './PanelDetails'
import { IDetailsGroup, IDetalle, IEdited } from '../interfaces'

const { Panel } = Collapse

export interface ICanceled {
    cancel: boolean
    setCancel: (value: boolean) => void
}

interface IPanelPrestacionesProps {
    data?: IDetailsGroup[]
    year: number
    month: number
    edited: IEdited
    setData: (value: IDetailsGroup[]) => void
    canceled: ICanceled
    refresh: () => void
}

export interface IButtonEdit {
    hcDetalles: IDetalle[]
    edited: boolean
    indexActual: number
    year: number
    month: number
    clicked: () => void
}

export const PanelPrestaciones = (props: IPanelPrestacionesProps) => {
  const { data, month, year, setData, canceled, edited, refresh } = props

  const PanelData = data?.map((dataGroup, dataGroupIndex) => {
    const { key, value } = dataGroup
    return <Panel
                 header={`Prestación: ${key}`}
                 key={key}
             >
              <PanelDetails
                value={value}
                edited={edited}
                canceled={canceled}
                year={year}
                refresh={refresh}
                month={month}
                setData={setData}
                dataGroup={dataGroup} />
           </Panel>
  })

  return <Collapse accordion expandIconPosition="start">
            { PanelData }
         </Collapse>
}
