import { useState, useEffect } from 'react'
import _ from 'lodash'
import { getClinicHistory } from '../../../repositories'
import { IDetailsGroup } from '../interfaces'
interface userDataProps {
    profesionalCode: number
    patientCode: number
    user: string
    role: number
    numAllHc: number
}
interface useClinicHistoryProps {
    params: userDataProps
    year: number
    month: string
}
export const useGetClinicHistory = ({ params, year, month }: useClinicHistoryProps) => {
  const [data, setData] = useState<IDetailsGroup[]>()
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const refreshData = () => {
    setRefresh(!refresh)
  }
  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const res = await getClinicHistory(params, year, month)
        const rawData = res?.data.data
        const dataSort = params.numAllHc === 0
          ? _.orderBy(rawData, ['fechaInicio', 'codigoLinea'], ['asc', 'asc'])
          : rawData

        const dataGroup = _.groupBy(dataSort, 'prestacion')
        const formattedData = Object.entries(dataGroup).map(([key, value]) => ({
          key,
          value
        } as IDetailsGroup))

        setData(formattedData)
      } catch (error) {
        console.error('Error fetching clinic history:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [year, month, refresh])

  return { data, loading, setData, refreshData }
}
