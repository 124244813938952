import React from 'react'
import { Link } from 'react-router-dom'
import { FaClinicMedical } from 'react-icons/fa'
import { RiBarChartFill } from 'react-icons/ri'
import { BiCalendarStar } from 'react-icons/bi'
import { GiMedicines } from 'react-icons/gi'
import './navInferior.css'
import { Footer } from 'antd/lib/layout/layout'
import { usePatient } from '../../services/patients/patientContextServices'

export const NavInferior = () => {
  const usePatientInfo = usePatient()
  const url = `Paciente/${usePatientInfo.patientCode}`

  const LinkStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    width: '500px',
    height: '70px',
    color: '#36bc9b',
    alignItems: 'center',
    flexDirection: 'column'
  }

  const FooterStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    background: '#fff',
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between'
  }

  return <Footer style={FooterStyles}>
    <Link className='navInferior-item' to={`/${url}/historia-clinica`}>
      <FaClinicMedical className='material-icons'/>
      <span className='navInferior-item-label'>Historia Clinica</span>
    </Link>
    <Link className='navInferior-item' to={`/${url}/evoluciones`} style={LinkStyle}>
      <RiBarChartFill className='material-icons' />
      <span className='navInferior-item-label'>Evoluciones</span>
    </Link>
    <Link className='navInferior-item' to={`/${url}/novedades`} style={LinkStyle}>
      <BiCalendarStar className='material-icons' />
      <span className='navInferior-item-label'>Novedades</span>
    </Link>
    <Link className='navInferior-item' to={`/${url}/stock`} style={LinkStyle}>
      <GiMedicines className='material-icons' />
      <span className='navInferior-item-label'>Stock</span>
    </Link>
  </Footer>
}
