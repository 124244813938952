import { OpenNotification } from 'components'
import { IDetalle } from '../interfaces'

export const isHCValid = (data: IDetalle): boolean => {
  if (!data.fechaVisita) {
    OpenNotification('Datos Incompletos', 'Se debe completar la fecha de la visita', 'top', 'error')
    return false
  }
  if (data.entradaFechaCarga && data.salidaFechaCarga && (!data.detalle || !data.tiempoAtencion)) {
    OpenNotification('Datos Incompletos', 'Se deben completar todos los datos (Tiempo, Comentario)', 'top', 'error')
    return false
  }
  if (data.entradaFechaCarga && data.salidaFechaCarga && (!data.detalle || data.detalle.length < 6)) {
    OpenNotification('Comentario Corto', 'Debe ingresar un comentario con un mínimo de 6 caracteres', 'top', 'error')
    return false
  }
  if (!data.detalle) {
    OpenNotification('Comentario Incompleto', 'Debe ingresar un comentario', 'top', 'error')
    return false
  }
  if (!data.firma || data.firma === '' || data.firma === ' ') {
    OpenNotification('Firma Incompleta', 'Debe ingresar una firma', 'top', 'error')
    return false
  }
  return true
}
