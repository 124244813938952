import { FormOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import { SaveAndCancelButton } from '../../../components'
import SignaturePad from 'react-signature-canvas'
import { useIsMobile } from '../../../services/responsive'
import { setIn } from 'timm'
import { IDetailsGroup } from '../interfaces'

interface ISignatureProps {
  disabled: boolean
  index: number
  data: IDetailsGroup
  setData: (value: any) => void
}

export const Signature = (props: ISignatureProps) => {
  const { data, index, setData, disabled } = props
  const value = data.value
  const [showModal, setShowModal] = useState<boolean>(false)
  const padRef = useRef<SignaturePad>(null)
  const ClearButton = (props?: any) => {
    return <Button {...props}>Limpiar</Button>
  }

  const styles = {
    border: '1px solid black'
  }
  return (<div>
             <h3 className='title-Hc'>Firma:</h3>
             <Button onClick={() => {
               padRef.current?.clear()
               setShowModal(!showModal)
             } } disabled={disabled}><FormOutlined /></Button>
             <Modal
                title={'Firma Profesional'}
                open={showModal}
                onCancel={() => setShowModal(!showModal)}
                onOk={() => setShowModal(!showModal)}
                closable={false}
                width={1000}
                maskClosable={false}
                key={index}
                footer={[
                    <ClearButton key={'clear'} onClick={() => padRef.current?.clear()}/>,
                    <SaveAndCancelButton key={'buttons'} disabled={false} clickedCancel={() => setShowModal(!showModal)} clickedSave={() => {
                      const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png')
                      const newData = setIn(value, [index, 'firma'], url)
                      const newDataGroup = setIn(data, ['value'], newData) as IDetailsGroup
                      setData([newDataGroup])
                      setShowModal(!showModal)
                    }} />
                ]}
                >
                 <SignaturePad ref={padRef} key={index} canvasProps={{ height: '300', width: useIsMobile() ? '350' : '950', style: styles }} />
             </Modal>
              <img
              id='horaAtencion'
              className={'sigImage'}
              src={value[index].firma}
              />
        </div>)
}
