import React, { useContext, useEffect, useState } from 'react'
import { Calendar, SpinLoad } from 'components'
import { Table, Tag, Typography } from 'antd'
import moment, { Moment } from 'moment'
import { agendaRepositories } from '../../repositories/agendaRepository'
import { AuthLoginContext } from '../../services/authContextService'
import { ColumnsType } from 'antd/lib/table'
import CurarteIcon from 'components/Common/CurarteIcons'
import { useHeaderPage } from '../../services/context/common/headerPageContextServices'

const { Text } = Typography

const baseRepository = agendaRepositories()

interface IAgendaData {
  fechaVisita: string
  nombrePaciente: string
  direccionAtencion: string
  estatus: string
  visita: number
}

interface IDataTypeAgenda extends IAgendaData {
  key: React.Key
}

const columns: ColumnsType<IDataTypeAgenda> = [
  {
    title: 'Fecha visita',
    dataIndex: 'fechaVisita'
  },
  {
    title: 'Paciente',
    dataIndex: 'nombrePaciente'
  },
  {
    title: 'Direccion atencion',
    dataIndex: 'direccionAtencion'
  },
  {
    title: 'Estatus',
    dataIndex: 'estatus',
    render: (estatus: string) => (
      <>
        {
          <Tag
            color={
              estatus.toUpperCase() === 'REALIZADA'
                ? '#36bc9b'
                : 'rgb(33, 150, 243)'
            }
          >
            <CurarteIcon
              name={
                estatus.toUpperCase() === 'REALIZADA'
                  ? 'CheckOutlined'
                  : 'FileTextOutlined'
              }
            />{' '}
            {estatus}
          </Tag>
        }
      </>
    )
  }
]

export const Agenda = () => {
  const userInfo = useContext(AuthLoginContext).userInfo
  const useHeader = useHeaderPage()
  useEffect(() => {
    useHeader.setHeaderPageTitle('Agenda')
    useHeader.setOnBack('/')
    useHeader.setActiveNavBot(false)
    useHeader.setActiveMoreOptionMenu(false)
  }, [])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [date, setDate] = useState<Moment>(moment())
  const [agendaData, setAgendaData] = useState<IDataTypeAgenda[]>()

  const agendaList = () => {
    setIsLoading(true)
    const fecth = async () => {
      return await baseRepository.list({
        codiogProfesional: userInfo.codigoProfesional,
        dateStart: date,
        dateEnd: date
      })
    }

    fecth().then((res) => {
      const response = res?.data.data as IAgendaData[]
      setAgendaData(
        response.map((el, index) => {
          return {
            key: index,
            fechaVisita: moment(el.fechaVisita).format('YYYY-MM-DD hh:mm:ss'),
            nombrePaciente: el.nombrePaciente,
            direccionAtencion: el.direccionAtencion,
            estatus: el.estatus,
            visita: el.visita
          }
        })
      )
      setIsLoading(false)
    })
  }

  useEffect(() => {
    agendaList()
  }, [date])

  return (
    <>
      {' '}
      <Text className="Select-Text">Fecha visita: </Text>
      <Calendar
        notShowTime={true}
        notModuleHC={true}
        defaultValue={moment()}
        onChange={(date) => setDate(date)}
      />
      <div style={{ marginTop: 20 }}>
        <SpinLoad spinning={isLoading}>
          <Table
            pagination={false}
            className="tabla-agenda"
            columns={columns}
            dataSource={agendaData}
            scroll={{ x: 600 }}
          />
        </SpinLoad>
      </div>
    </>
  )
}
