import { CheckOutlined } from '@ant-design/icons'
import { accountConfigServicesV2 } from '../../../services/accountConfigServices'
import { Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import { IDetalle } from '../interfaces'

interface IGeolocationProps {
    edited: boolean
    defaultValueEntrada: string
    defaultValueSalida: string
    onChange: (value: any) => void
    value: IDetalle
}
interface IConfigDataProps {
    codigo:number
    codigoEntidad:number
    descripcion:string
    valor: string
}

export const Geolocation = (props: IGeolocationProps) => {
  const [configData, setConfigData] = useState<IConfigDataProps>(undefined!)
  useEffect(() => {
    const getConfig = async () => {
      return await accountConfigServicesV2().getAllConfig(props.value.codigoEntidad)
    }

    getConfig().then((res) => {
      const data = res?.data.data as IConfigDataProps
      setConfigData(data)
    })
  }, [])

  return configData?.codigo === 501 && parseInt(configData?.valor) > 0
    ? (<div>
            <h3 className='title-Hc'>Geolocalizacion:</h3>
            <div className='button-geolocation'>
                <div className='button-geolocation-entrada'>
                    <Radio.Group buttonStyle="solid" defaultValue={props.defaultValueEntrada}>
                        <Radio.Button value="entrada" disabled={props.edited} onChange={props.onChange}> { !props.defaultValueEntrada ? <></> : <CheckOutlined />} Entrada</Radio.Button>
                    </Radio.Group>
                </div>
                <div className='button-geolocation-salida'>
                    <Radio.Group buttonStyle="solid" defaultValue={props.defaultValueSalida}>
                        <Radio.Button value="salida" disabled={props.edited} onChange={props.onChange}> { !props.defaultValueSalida ? <></> : <CheckOutlined />} Salida</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
      </div>)
    : null
}
