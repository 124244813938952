import Axios, { AxiosRequestConfig } from 'axios'
import { authService } from './authService'
import { OpenNotification } from 'components'

export const accountConfigServices = async (values: any, valueYear: number, valueMonth: string) => {
  authService()
  const api = Axios.get(`${process.env.REACT_APP_URI_BASE}/Account/Config?codigoEntidad=${values.codigoEntidad}`)
  return await api
}

export const accountConfigServicesV2 = () => {
  authService()
  return {
    getAllConfig: async (codigoEntidad: number) => {
      const config: AxiosRequestConfig = {
        method: 'GET',
        url: `${process.env.REACT_APP_URI_BASE}/Account/Config`,
        params: {
          codigoEntidad
        }
      }
      try {
        const response = await Axios(config)

        return response
      } catch (e) {
        OpenNotification('Error', 'No se pudo realizar la carga.', 'top', 'error')
        return null
      }
    }
  }
}
